@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --apple-white: #F5F4F2;
  --apple-black: #212124;
  --background: #D3FFB3;
  --head-foot-bg: #33AB5F;
  --article-bg: #93DCAE;
  --article-heading: #3A4A40;
  --about-section-bg: rgba(255,255,173,0.85);
  --btn-blue: #006FAD;
}

@layer components {
  /* <Universal> */
  .hyperlink {
    @apply
      underline 
      text-blue-500 
      hover:bg-[var(--article-bg)]
  }
  /* </Universal> */

  /* <NavBar> */
  .nav-btn {
    @apply 
      text-[var(--apple-white)] 
      px-6
      py-12 
      hover:text-[var(--apple-black)] 
      hover:bg-[var(--background)] 
      duration-500
  }

  .nav-btn-mobile {
    @apply
      block 
      text-[var(--apple-white)] 
      px-8 
      py-10
  }
  /* </NavBar> */

  /* <Footer> */
  .footer-li {
    @apply
      mb-2 
      md:mb-0 
      md:mr-8
  }

  .footer-btn {
    @apply
    text-[var(--apple-white)] 
    text-xl 
    md:hover:underline
  }
  /* </Footer> */

  /* <HomePage> */
  .expandable-box {
    @apply
      w-[80vw]
      p-5
      bg-[#73AEFE] 
      rounded-lg 
      overflow-hidden
      shadow-xl
      md:whitespace-nowrap 
      md:duration-[1200ms] 
  }
  /* </HomePage> */

  /* <AboutPage> */
  .about-contact-logo {
    @apply
      w-10 
      h-10 
      md:w-16 
      md:h-16
  }
  /* </AboutPage> */

  /* <PortfolioPage> */
  .port-filter-box {
    @apply
      border 
      rounded 
      border-black 
      p-2
  }
  /* </PortfolioPage> */

  /* <ProjectPage> */
  .project-info {
    @apply
      font-semibold 
      text-xl 
      text-blue-800 
      col-span-1
  }

  .project-text {
    @apply
      my-4
  }
  /* </ProjectPage> */

  /* <LogIn + SignUp> */
  .log-sign-h1 {
    @apply
      text-center 
      font-bold 
      text-3xl 
      md:text-4xl 
      mb-8 
      md:mb-12
  }

  .log-sign-error {
    @apply
      mb-6 
      -mt-6 
      text-red-500 
      text-center
  }

  .log-sign-fieldset {
    @apply
      flex 
      flex-col 
      items-center 
      border-2 
      md:border-[3px] 
      rounded 
      border-gray-800 
      p-2
      md:p-8
  }

  .log-sign-input-row {
    @apply 
      grid 
      grid-cols-1 
      md:grid-cols-2 
      my-2 
  }

  .log-sign-input-box {
    @apply
      border 
      rounded 
      border-black 
      pl-3
      w-[70vw]
      md:w-auto
  }

  .pwd-container {
    @apply
      relative 
      flex 
      items-center
  }

  .log-sign-submit-btn {
    @apply
      w-[100%]
      cursor-pointer
      my-2 
      p-3
      rounded-2xl 
      bg-gray-300 
      md:hover:bg-[var(--apple-white)] 
      md:duration-500
  }
  /* </LogIn + SignUp> */

  /* <EmailVerify> */
  .email-verify-main {
    @apply
      flex 
      flex-col 
      justify-center 
      items-center 
      text-center
      p-16
      md:p-60
      max-h-[66vh]
  }

  .email-verify-h1 {
    @apply
      font-bold
      text-2xl 
      md:text-4xl 
  }

  .email-verify-p {
    @apply
      my-5
      md:my-10
  }

  .email-verify-btn {
    @apply
      p-6 
      font-bold 
      border-4 
      rounded-lg 
      border-[var(--btn-blue)] 
      shadow-xl 
      duration-700 
      hover:bg-[#006FAD] 
      hover:text-[var(--apple-white)]
  }
  /* </EmailVerify> */
}

body {
  font-family: sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

::selection {
  background-color: #CBC8DB;
}

img::selection {
  background-color: transparent;
}

header a.active {
  color: #212124;
  background-color: #D3FFB3;
}

footer a.active {
  text-decoration: underline;
}

.about-me-btn {
  background: linear-gradient(to top, var(--btn-blue) 50%, var(--apple-white) 50%);
  background-size: 100% 200%;
  transition: background-position 0.5s ease-in-out;
}

.about-me-btn:hover {
  background-position: 0 100%;
}

div.expandable-box:hover span {
  display: inline;
}
